import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userReducer } from "../slices/user";
import { reducer as modalsReducer } from "../slices/modals";
import { reducer as departementReducer } from "../slices/departement";
import { reducer as roleReducer } from "../slices/role";
import { reducer as holidayReducer } from "../slices/holidays";
import { reducer as leaveReducer } from "../slices/leave";
import { reducer as leaveTypesReducer } from "../slices/leavetype";
import { reducer as notificationsReducer } from "../slices/notifications";
import { reducer as statusReducer } from "../slices/status";
import { reducer as permissionReducer } from "../slices/permission";
import { reducer as authorisationReducer } from "../slices/authorisation";
import { reducer as exportReducer } from "../slices/export";

const combinedReducer = combineReducers({
  user: userReducer,
  modals: modalsReducer,
  department: departementReducer,
  role: roleReducer,
  holidays: holidayReducer,
  leave: leaveReducer,
  authorisation: authorisationReducer,
  leaveTypes: leaveTypesReducer,
  notifications: notificationsReducer,
  status: statusReducer,
  permission: permissionReducer,
  export:exportReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
